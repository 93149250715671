<template>
  <div>
    <div class="holder">
      <SmallNav />
      <div v-if="!loader" class="contaner">
        <div class="card_holder">
          <div class="card">
            <div class="agreement_text_header">
              <h5>Tenancy Agreement</h5>
              <div class="blue_line"></div>
            </div>
            <hr class="divider_line" />

            <div class="tenancy_header">
              <h6>
                {{ agrementData }}
              </h6>
            </div>

            <div class="agreement_body">
              <h6 v-if="agreementBody != null && agreementBody != ''">
                {{ agreementBody }}
              </h6>
              <h6 v-else>
                No agreement for this property
              </h6>
            </div>

            <div class="form-check form_check">
              <input
                class="form-check-input "
                type="checkbox"
                value=""
                id="flexCheckChecked"
                v-model="checkagrement"
              />
              <label class="form-check-label" for="flexCheckChecked">
                I admit to have read the tenency agreement, and i agree to the
                stated terms and conditions
              </label>
              <p class="checkBoxx">{{ checkBox }}</p>
            </div>

            <div class="sign_agg_btn">
              <!-- @click="signAgreement" -->
              <button @click="signAgreement" class="btn btn-block">
                <!-- data-toggle="modal"
                data-target="#showpayment" -->
                SIGN
                <i
                  class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                  v-if="loader1"
                ></i>
              </button>
              <!-- <p @click="trigerRentPayment(modeData)">Open payment modal</p> -->
            </div>
          </div>
        </div>
      </div>

      <!-- Modal to validate Agrement starts -->
      <div
        class="modal fade"
        id="otp_box"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header opt-head">
              <!-- <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5> -->
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p class="modal_veri_text">
                A verification code has been sent to your registered email.
              </p>
              <h6 class="enter_code_text">Kindly enter the code here</h6>
              <!-- <h5 class="otp__text">{{OTP}}</h5> -->
              <div class="otp-holder">
                <input
                  @keyup="keytab($event)"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  v-model="one"
                  type="number"
                  class="form-control"
                  id="exampleInputEmail1"
                  maxlength="1"
                />
                <input
                  @keyup="keytab($event)"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  v-model="two"
                  type="number"
                  class="form-control"
                  id="exampleInputEmail1"
                  maxlength="1"
                />
                <input
                  @keyup="keytab($event)"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  v-model="three"
                  type="number"
                  class="form-control"
                  id="exampleInputEmail1"
                  maxlength="1"
                />
                <input
                  @keyup="keytab($event)"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  v-model="four"
                  type="number"
                  class="form-control"
                  id="exampleInputEmail1"
                  maxlength="1"
                />
                <input
                  @keyup="keytab($event)"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  v-model="five"
                  type="number"
                  class="form-control"
                  id="exampleInputEmail1"
                  maxlength="1"
                />
              </div>

              <div class="otp__btn">
                <!-- <button class="btn" @click="concatOtp()">
                  Sign Document with OTP
                  <i
                    class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                    v-if="loader"
                  ></i>
                </button> -->
                <button class="btn" @click="concatOtp">
                  Sign Document with OTP
                  <i
                    class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                    v-if="loader"
                  ></i>
                </button>
                <p>
                  Didn’t get the code?
                  <span @click="concatOtp">Resend Code</span>
                </p>
                <!-- <p>Didn’t get the code? <span @click="signAgreement">Resend Code</span></p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal to validate Agrement ends -->

      <!-- Modal to fund wallet begins -->
      <!-- <div
        class="modal fade"
        id="fundme"
        tabindex="-1"
        role="dialog"
        aria-labelledby="fundme"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="close_bank">
              <small data-dismiss="modal" aria-label="Close">x</small>
            </div>
            <div class="modal-body mb">
              <div class="payment_header">
                <p class="payer_name">Great job </p>
                <div class="check_img">
                  <img src="../assets/images/check-good.svg" alt="check" />
                </div>
                <h5 class="document_text">Document signing complete</h5>
                <p class="electronic_text">
                  An electronic copy of the signed agreement will be sent to
                  your registered email
                </p>
                <h6 class="proceed">Proceed to make payment</h6>
              </div>
              <div class="funds_wrap">
                <h5 class="fund_title">Amount Payable</h5>
                <h4 class="fund_amount">&#8358;{{ modeData.price }}</h4>
                <div class="funds_options">
                  <div class="fund_type">
                    <paystack
                      class="paystackBtn"
                      :amount=" modeData.price * 100"
                      :email="email"
                      :paystackkey="PUBLIC_KEY"
                      :reference="reference"
                      :callback="processPayment"
                      :close="close"
                    >
                      <div class="paystack_div">
                        <img src="../assets/images/block.svg" alt="" />
                        <small>
                          Paystack
                        </small>
                      </div>
                    </paystack>
                  </div>
                  <div class="fund_type">
                    <button class="btn btn-block wallet_withdraw" disabled>
                      <img
                        src="../assets/images/pay-wallet.svg"
                        alt="transfer"
                        width="20%"
                      />Pay from wallet
                    </button>
                  </div>
                  <div class="fund_type">
                    <button
                      class="btn btn-block bank"
                      @click="movetomodalTransfer"
                    >
                      <img
                        src="../assets/images/double-arrow.svg"
                        alt="transfer"
                      />
                      Bank Transfer
                    </button>
                  </div>

                  <div class="fund_type">
                    <button class="btn btn-block flutter" disabled>
                      <img
                        src="../assets/images/flutter.svg"
                        alt="transfer"
                      />Flutterwave
                    </button>
                  </div>
                  <div class="fund_type">
                    <button class="btn btn-block enaira" disabled>
                      <img
                        src="../assets/images/e-naira.svg"
                        alt="transfer"
                      />e-Naira
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- Modal to Fund wallet -->

      <!-- Modal for bank statment upload begins -->
      <!-- <div
        class="modal fade"
        id="showpayment"
        tabindex="-1"
        role="dialog"
        aria-labelledby="showpayment"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header payment_header_holder">
              <img
                v-if="headerText2"
                @click="switchView(0)"
                class="back__arrow"
                src="../assets/images/arrow2-white.svg"
                alt=""
              />
              <img
                v-if="headerText3"
                @click="switchView(1)"
                class="back__arrow"
                src="../assets/images/arrow2-white.svg"
                alt=""
              />
              <h5
                v-if="headerText1"
                class="modal-title payment-header"
                id="exampleModalLongTitle"
              >
                Pay My Rent
              </h5>
              <h5
                v-if="headerText2"
                class="modal-title payment-header"
                id="exampleModalLongTitle"
              >
                Proof of payment upload.
              </h5>
              <h5
                v-if="headerText3"
                class="modal-title payment-header"
                id="exampleModalLongTitle"
              >
                Call Shelta.
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" class="close_times">&times;</span>
              </button>
            </div>
            <div id="onee" class="modal-body">
              <div class="">
                <div class="dot-holder">
                  <span class="step active"></span>
                  <span class="step"></span>
                  <span class="step"></span>
                </div>
                <div class="double_arrow_holder">
                  <img
                    src="../assets/images/double-arrow-blue.svg"
                    alt="back"
                  />
                  <p>Bank Transfer</p>
                </div>
                <div class="form-outter">
                  <div class="first-step">
                    <div class="number__holder">
                      <p class="span-count">1</p>
                      <p>
                        Transfer the amount due to the acount number below
                      </p>
                    </div>

                    <div class="sheltaAccount__details">
                      <div>
                        <p>Amount:</p>
                        <p>&#8358;{{ modeData.price }}</p>
                      </div>
                      <div>
                        <p>Account Name:</p>
                        <p>Shelta Panacea Limited</p>
                      </div>
                      <div>
                        <p>Account Number :</p>
                        <p>0708490113</p>
                      </div>
                      <div>
                        <p>Bank :</p>
                        <p>Guarantee Trust Bank</p>
                      </div>
                    </div>
                    <div class="number__holder second__number">
                      <p class="span-count">2</p>
                      <p>
                        After the transfer is completed, please keep your
                        payment receipt by screenshot or photo and upload it in
                        the next step.
                      </p>
                    </div>

                    <div class="desclaimer__text">
                      <p>
                        Kindly note that your rent starts running 48 hours after
                        your payment is approved
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-foot">
                <button
                  @click="switchView(1)"
                  type="button"
                  class="btn next-btn btn-block"
                >
                  Next
                </button>
              </div>
            </div>

            <div id="twoo" class="modal-body">
              <div class="container-fluid">
                <div class="dot-holder">
                  <span class="step"></span>
                  <span class="step active"></span>
                  <span class="step"></span>
                </div>
                <div class="form-outter">
                  <div class="second-step">
                    <p class="info__text">
                      Please upload the payment receipt so that we can find your
                      payment.
                    </p>
                    <div class="dateOf__payment">
                      <label for="">Date of payment <span>*</span></label>
                      <input
                        class="form-control"
                        name="date"
                        type="date"
                        placeholder="Select date of payment"
                      />
                    </div>

                    <div class="upload__file">
                      <div class="dropzone" ref="dropzone">
                        <input
                          @change="selectFile($event)"
                          class="input-file"
                          ref="file"
                          type="file"
                        />
                        <img src="../assets/images/file-upload.svg" alt="" />
                        <img
                          v-if="fileselected"
                          class="actual_image"
                          :src="filePath"
                          alt=""
                        />
                        <p
                          v-if="!uploading && !fileselected"
                          class="call-to-action"
                        >
                          Drag and drop or
                          <span class="browse__span">browse</span> your files
                        </p>
                        <h6>{{ fileName }}</h6>

                        <p
                          v-if="uploading"
                          class="call-to-action"
                          style="color: green;"
                        >
                          Uploding your reciept...
                        </p>
                      </div>
                      <p
                        v-if="uploading"
                        class="call-to-action"
                        style="color: green;"
                      >
                        Uploding your reciept...
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-foot">
                <button
                  @click="sendFile"
                  type="button"
                  class="btn next-btn btn-block"
                >
                  Submit
                </button>
              </div>
            </div>

            <div id="threee" class="modal-body">
              <div class="container-fluid">
                <div class="dot-holder">
                  <span class="step"></span>
                  <span class="step"></span>
                  <span class="step active"></span>
                </div>
                <div class="form-outter">
                  <div class="third-step">
                    <img src="../assets/images/rent-call.svg" alt="" />
                    <p>
                      Submited successfully! We will confirm your payment and
                      update your status within 48 hours. For enquiries please
                      call our customer service agent on :
                    </p>
                  </div>
                </div>
              </div>
              <div class="modal-foot">
                <button type="button" class="btn call-btn">
                  <a href="tel:2348061796909">
                    <img src="../assets/images/phone-conect.svg" alt="" />
                    08061796909
                  </a>
                </button>
              </div>
              <router-link class="backt0dashboard" to="/dashboard/tenant">
                <small data-dismiss="modal" aria-label="Close"
                  >Back to dashboad
                </small>
              </router-link>
              <small class="close_small" data-dismiss="modal" aria-label="Close"
                >Close</small
              >
            </div>
          </div>
        </div>
      </div> -->
      <!-- Modal for bank statment upload ends -->

      <div v-if="loader">
        <PageLoader></PageLoader>
      </div>

      <!-- <component class="more-content" :is="PaymentModal"></component> -->
      <PaymentModal
        @paystackReference="dataFromChild($event)"
        @action="dataFromChild($event)"
        @closee="close($event)"
        :dataForPayment="modalData"
        :responseData="resData"
        v-model="paymentM"
      ></PaymentModal>
      <!-- <component class="" :header="headerCaption" :is="PaymentModal"></component> -->
    </div>
  </div>
</template>

<script>
import SmallNav from "./SmallNav";
import { Service } from "../store/service";
const Api = new Service();
import $ from "jquery";
// import paystack from "vue-paystack";
// import { staticdata } from "../../src/data";
import PaymentModal from "./PaymentModal.vue";
import PageLoader from "./PageLoader.vue";
export default {
  name: "SwitchTenancyAgreement",
  components: {
    SmallNav,
    // paystack,
    PaymentModal,
    PageLoader
    // FooterMain,
  },
  data() {
    return {
      one: "",
      two: "",
      three: "",
      four: "",
      five: "",
      uploadFile: "",
      fileselected: false,
      uploading: false,
      loadingOTP: false,
      loader: "",
      loader1: false,
      // loadingAg: false,
      checkagrement: "",
      OTP: "",
      token: "",
      concateAggrment: "",
      agrementData: "",
      agrementdata: "",
      agreementBody: "",
      switchDetinationMode: "",
      checkBox: "",
      progress: 0,
      headerText1: true,
      headerText2: false,
      headerText3: false,
      filePath: "",
      userid: "",
      propertyPin: "",
      file: "",
      fileName: "",
      filePath: "",
      OTP: "",
      propPin: "",
      propId: "",
      modeData: "",
      // PUBLIC_KEY: staticdata.paystackkeylive,
      email: "",
      full_name: "",
      reference: "",
      user: "",
      modalData: {},
      paymentM: "",
      resData: ""
    };
  },
  created() {
    this.$root.$refs.A = this;
    this.$root.$refs.C = this;
  },
  mounted() {
    this.userid = JSON.parse(localStorage.getItem("shelta-user")).id;
    this.user = JSON.parse(localStorage.getItem("shelta-user"));
    this.email = this.user.email;
    this.full_name = this.user.firstname + this.user.lastname;
    this.modeData = JSON.parse(localStorage.getItem("switchable"));
    this.fetchPropertyAgrement();
  },
  methods: {
    processPaymentPaystack(res) {
      // console.log("clikedd", res);
      const ref = res;
      this.loader = true;
      Api.postRequest(`switchnow`, {
        switchid: this.modeData.switchid,
        destinationTenantModeOfPaymentsId: Number(
          this.modeData.destinationTenantModeOfPaymentsId
        ),
        referencecode: ref
      })
        .then(res => {
          // console.log("switch response", res);
          this.loader = false;
          if (res.data.success === "success") {
            const vex = localStorage.getItem("switchable");
            if (Object.keys(vex).length > 0) {
              localStorage.removeItem("switchable");
            }
            return this.$store.commit("setApiSuccess", "Payment is successful");
          }
          if (res.data.error) {
            return this.$store.commit("setApiFailed", res.data.error);
          }
        })
        .catch(err => {
          this.loader = false;
          console.log("err", err);
          // return this.$store.commit("setApiFailed", "Error occured");
        });
    },
    // Paystack close function or cancel payment
    dataFromChild(data) {
      // console.log("Data from child", data);
      if (
        data.action === true &&
        this.modalData.paymentType === "rentpaymentswitch" &&
        data.type === "paystack"
      ) {
        this.processPaymentPaystack(data.ref);
      }
      // if (
      //   data.action === true &&
      //   this.modalData.paymentType === "rentpayment" &&
      //   data.type === "payfromsafe"
      // ) {
      //   // this.payMyRentWithSafe();
      // }
    },
    // close: () => {
    //   console.log("You closed checkout page");
    // },
    movetomodalTransfer() {
      $("#fundme")
        .removeClass("fade")
        .modal("hide");
      $("#showpayment")
        .addClass("fade")
        .modal("show");
    },
    trigerRentPayment: function(propInfo) {
      this.fundLoader = true;
      this.modalData.amount = 0;
      this.modalData.payableAmount = this.modeData.price;
      this.modalData.subtitle = "Payment Amount";
      this.modalData.paymentType = "rentpaymentswitch";
      this.modalData.headerCaption = "RENT PAYMENT SWITCH";
      this.modalData.headerBackground = "#0033ea";
      // this.modalData.paymentURL = "";
      this.$root.$refs.A.openPaymentOptionsModal(propInfo);
    },
    close(data) {
      if (data.close === "yes") {
        this.loader = false;
        // console.log("Closed All");
        $("#otp_box")
        .removeClass("fade")
        .modal("hide");
        this.$root.$refs.X.closeModalAll();
        location.reload();
      }
    },
    async signAgreement() {
      this.loader1 = true;
      // this.loadingAg = true;
      // console.log("Property pin", this.propPin);
      this.concateAggrment = this.agrementData.concat(this.agreementBody);
      // console.log("Agrement tm", this.checkagrement);
      if (this.checkagrement === false || this.checkagrement === "") {
        // this.$store.commit("setApiWarning", "Check the box");
        this.checkBox = "Please check the box";
        this.loader1 = false;
      } else {
        this.checkBox = "";
        try {
          await Api.postRequest(`signaggreement`, {
            userid: this.userid,
            propertyid: this.propId,
            agreement: this.concateAggrment
            // tenantmodeofpaymentid: this.modeData.
          })
            .then(res => {
              // this.loadingAg = false;
              if (res.data.error) {
                this.$store.commit("setApiFailed", res.data.error);
              } else {
                // console.log("Signed agreement", res);
                // this.OTP = res.data.message.otp;
                // console.log("OTP", this.OTP);
                this.$store.commit("setApiSuccess", "OTP has been sent to you");
                this.loader1 = false;
                // this.$router.push({path: `/dashboard/switchagreement`});

                $("#otp_box")
                  .addClass("fade")
                  .modal("show");

                // $("#otp_box").modal("show");
              }
            })
            .catch(err => {
              console.log(err);
              // this.loader = false;
              this.loader1 = true;
              // this.loadingAg = false;
            });
        } finally {
          // console.log("Closed connection");
        }
      }
    },
    fetchPropertyAgrement: async function() {
      // console.log('Pinnnnn', this.searchPin);
      // this.$router.push({ path: `${this.searchPin}` });
      this.propPin = this.$route.params.id;
      // const id = this.searchPin;
      try {
        await Api.getRequest(
          `getagreementbyuseridandpropertypin/${this.userid}/${this.propPin}`
        )
          .then(res => {
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            } else {
              // console.log("Property Agrement", res);
              this.agrementdata = res.data;
              this.agrementData = res.data.data;
              this.agreementBody = res.data.body;
              this.propId = res.data.propertyid;
              this.agreementType(res.data.switch_agreement);
            }
          })
          .catch(err => {
            console.log(err);
            // this.loader = false;
            // this.uploading = true;
          });
      } finally {
        // console.log("Closed connection");
      }

      // console.log('Property ID', id);
    },
    agreementType(ag) {
      this.switchDetinationMode = JSON.parse(
        localStorage.getItem("switchable")
      ).destination_mode;
      if (this.switchDetinationMode === "Quarterly") {
        return (this.agreementBody = ag.quaterly);
      }
      if (this.switchDetinationMode === "Monthly") {
        return (this.agreementBody = ag.monthly);
      }
      if (this.switchDetinationMode === "Bi-Annually") {
        return (this.agreementBody = ag.biannual);
      }
      if (this.switchDetinationMode === "Annually") {
        return (this.agreementBody = ag.annual);
      }
    },
    // selectFile(event) {
    //   const file = this.$refs.file.files[0];
    //   // console.log("Fimile", event.target.value);
    //   this.filePath = URL.createObjectURL(file);
    //   this.file = file;
    //   this.fileName = file.name;
    //   // console.log("fileeeeeeeeeeee", this.filePath);
    //   if (this.file) {
    //     this.fileselected = true;
    //   } else {
    //     this.fileselected = false;
    //   }
    // },
    // async sendFile() {
    //   // this.switchView(2)
    //   const file = this.$refs.file.files[0];
    //   // this.file = file;
    //   if (!this.file) {
    //     // this.loaderM = false;
    //     return this.$store.commit("setApiFailed", "No file was selected!");
    //   }
    //   // console.log("dataaaaaaaaaaa", this.modeData);
    //   const formData = new FormData();
    //   formData.append("file", this.file);
    //   formData.append("switchid", this.modeData.switchid);
    //   formData.append(
    //     "destinationTenantModeOfPaymentsId",
    //     Number(this.modeData.destinationTenantModeOfPaymentsId)
    //   );
    //   try {
    //     this.uploading = true;
    //     await Api.postRequest(`switchnow`, formData, {
    //       onUploadProgress: (e) =>
    //         (this.progress = Math.round((e.loaded * 100) / e.total)),
    //     })
    //       .then((res) => {
    //         if (res.data.error) {
    //           this.uploading = false;
    //           this.$store.commit("setApiFailed", res.data.error);
    //         } else {
    //           this.$store.commit("setApiSuccess", "Success");
    //           window.localStorage.removeItem("switchable");
    //           this.switchView(2);
    //           this.uploading = false;
    //         }
    //         // console.log("Uploaded file", res);
    //         // this.$store.commit("setApiWarning", '');

    //         // this.message = "File has been uploaded";
    //         // this.file = '';
    //         // this.error = false;
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //         this.loader = false;
    //         this.uploading = false;
    //       });
    //   } finally {
    //     console.log("Closed connection");
    //   }
    // },
    keytab(event) {
      const nextInput = event.srcElement.nextElementSibling; // get the sibling element

      const target = event.target || event.srcElement;
      // const id = target.id;
      // console.log(nextInput); // prints undefined
      if (nextInput === null) {
        // check the maxLength from here
        return;
      } else {
        nextInput.focus();
      } // focus if not null
    },
    concatOtp() {
      const theOtp = this.one.concat(
        this.two,
        this.three,
        this.four,
        this.five
      );
      this.token = theOtp;
      // console.log('one OTP', theOtp);
      this.sendOTP();
    },
    async sendOTP() {
      // this.token = theOtp;
      this.loader = true;
      // console.log('OTP', this.token);
      try {
        await Api.postRequest(`validateagreementtoken`, {
          userid: this.userid,
          token: this.token
        })
          .then(res => {
            if (res.data.error) {
              this.loader = false;
              this.$store.commit("setApiFailed", res.data.error);
            }
            if (res.data.success) {
              // $("#fundme")
              //   .addClass("fade")
              //   .modal("show");
              // console.log(res);
              this.loader = false;
              this.trigerRentPayment(this.modeData);
              this.$store.commit("setApiSuccess", "Agreement signed");
              $("#otp_box")
                .removeClass("fade")
                .modal("hide");
            }
          })
          .catch(err => {
            console.log(err);
            this.loader = false;
          });
      } finally {
        // console.log("Closed connection");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$tatiary: #a2b0e3;
$yellow: #ffb100;
$black: #000000;
$black2: #222222;

@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

.holder {
  height: 100%;
}

.card_holder {
  max-width: 743px;
  width: 100%;
  margin: auto;
  padding-top: 49px;
}

.backt0dashboard {
  margin-right: 10px;
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
.close_small {
  border-left: 1px solid black;
  padding-left: 10px;
  color: red;
  cursor: pointer;
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
}
.close_small:hover {
  color: rgb(144, 3, 3);
}

.agreement_text_header {
  position: relative;
  h5 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    display: flex;
    padding: 28px 59px 0;
  }
  .blue_line {
    position: absolute;
    background: $primary;
    width: 177px;
    height: 5px;
    bottom: 0%;
    margin-left: 59px;
  }
}

.sign_agg_btn {
  padding: 19px 61px 42px;
  button {
    background: $primary;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    align-items: center;
    text-align: center;
    outline: 0;
    box-shadow: none;
  }
}

.tenancy_header {
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 26px;
    text-align: left;
    color: $black;
    margin: 49px 59px 37px;
    margin-bottom: 0;
  }
}

.divider_line {
  margin-top: 0;
}

.agreement_body {
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    color: $black;
    padding: 37px 61px;
    text-align: justify;
  }
}

.form_check {
  margin: 19px 20px 19px 61px;
  text-align: start;
  label {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    // line-height: 37px;
    color: rgba(0, 0, 0, 0.8);
  }
}
// form-check-input
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #6c6c6c;
}
.checkBoxx {
  color: #dc3545;
  font-family: Gotham;
  text-align: left;
  padding-top: 0.5rem;
  // margin-bottom: 0;
}

// OTP Agreement begins

.opt-head {
  border-bottom: none;
}

.modal-body {
  .modal_veri_text {
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 23px;
    align-items: center;
    text-align: center;
    margin-bottom: 0;
    color: $black;
  }
  .enter_code_text {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 23px;
    align-items: center;
    text-align: center;
    color: $black;
    padding-bottom: 2rem;
  }
}

.otp-holder {
  display: flex;
  justify-content: space-evenly;
  input {
    width: 45px;
    border: none;
    border-bottom: 2px solid #000000;
    border-radius: 0;
    box-shadow: none;
  }
}

.otp__text {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  color: #28a745;
  font-weight: bold;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.otp__btn {
  padding: 3rem 10px;
  button {
    background: $primary;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    align-items: center;
    text-align: center;
    max-width: 377px;
    width: 100%;
    max-height: 45px;
    height: auto;
    box-shadow: none;
    margin-bottom: 2rem;
  }
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    align-items: center;
    text-align: center;
    color: $black;
    span {
      font-weight: bold;
      cursor: pointer;
    }
  }
}

/* OTP Agrement ends */

// Payment option and fund me modal starts
.close_bank {
  display: flex;
  justify-content: right;
  padding: 10px 15px;
  align-items: baseline;
  // background: #efefef;
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: $black;
  }
  small {
    font-family: Gotham;
    padding-left: 1px;
    text-align: center;
    background: #efefef;
    // background: #c4c4c4;
    color: $black;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.check_img {
  img {
    width: 69.85px;
  }
}
.payer_name {
  font-family: Gotham;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: rgba(0, 0, 0, 0.8);
  padding-left: 1rem;
  padding-right: 1rem;
}
.document_text {
  font-family: Gotham;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 23px;
  color: $primary;
  margin-top: 16px;
  color: rgba(0, 0, 0, 0.8);
  padding-left: 1rem;
  padding-right: 1rem;
}

.proceed {
  font-family: Gotham;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 23px;
  color: $primary;
  padding-left: 1rem;
  padding-right: 1rem;
}

.electronic_text {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 23px;
  color: rgba(0, 0, 0, 0.8);
  padding-left: 1rem;
  padding-right: 1rem;
}

// .fund_title {
//   font-family: Gotham;
//   font-style: normal;
//   font-weight: 400;
//   font-size: 20px;
//   line-height: 30px;
//   color: $black;
//   padding-left: 1rem;
//   padding-right: 1rem;
// }
// .fund_amount {
//   font-family: Gotham;
//   font-style: normal;
//   font-weight: 400;
//   font-size: 30px;
//   line-height: 33px;
//   color: $black;
//   padding-left: 1rem;
//   padding-right: 1rem;
// }
// .funds_wrap {
//   // border: 1px solid #E5E5E5;
//   box-sizing: border-box;
//   padding: 40px 52px;
//   border-radius: 10px;
//   background: linear-gradient(0deg, #eff2fe, #eff2fe),
//     linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
//   box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
// }

// .funds_options {
//   margin: 10px 22px;
// }

// .fund_type {
//   padding: 5px 15px;
//   button {
//     height: 40px;
//     color: $secondary;
//     font-family: Gotham;
//     font-style: normal;
//     font-weight: normal;
//     font-size: 15px;
//     line-height: 30px;
//     box-sizing: border-box;
//     display: flex;
//     padding: 7px 25px;
//     align-items: center;
//     img {
//       padding-right: 10px;
//     }
//   }
//   .bank {
//     background: $primary;
//     outline: 0;
//     box-shadow: none;
//   }
//   .wallet_withdraw {
//     background: #1d95c8;
//     outline: 0;
//     box-shadow: none;
//   }
//   .paystack {
//     background: #56c1f2;
//     outline: 0;
//     box-shadow: none;
//   }
//   .flutter {
//     background: $yellow;
//     outline: 0;
//     box-shadow: none;
//   }
//   .enaira {
//     background: #16780d;
//     margin-bottom: 5px;
//     outline: 0;
//     box-shadow: none;
//   }

//   .paystackBtn {
//     color: $secondary;
//     border: none;
//     background: #56c1f2;
//     // background: $secondary;
//     font-family: Gotham;
//     font-style: normal;
//     font-weight: 400;
//     font-size: 14px;
//     // margin-left: 10px;
//     box-shadow: none;
//     outline: 0;
//     // max-width: 262px;
//     width: 100%;
//     border-radius: 5px;
//     font-size: 15px !important;
//     // height: 51px;
//     small {
//       font-size: 15px;
//     }
//   }
//   .paystackBtn:hover {
//     color: #1d3c4b;
//     font-size: 17px;
//     cursor: pointer;
//   }
// }
// // Payment option and fund me modal ends

// // Bank transfer upload modal
// .payment_header_holder {
//   background: $primary;
// }

// .mb {
//   padding: 0;
// }

// .payment-header {
//   font-family: Gotham;
//   font-style: normal;
//   font-weight: 700;
//   font-size: 20px;
//   line-height: 30px;
//   display: flex;
//   align-items: flex-end;
//   color: $secondary;
// }

// .close_times {
//   color: #ea0000;
//   background: $secondary;
//   border-radius: 50%;
//   width: 10px;
//   height: 10px;
//   padding: 0px 6px;
// }

// .dot-holder {
//   text-align: left;
//   margin-top: -10px;
//   margin-bottom: 10px;
// }

// .double_arrow_holder {
//   p {
//     margin-top: 5px;
//     color: $primary;
//     font-family: Gotham;
//     font-style: normal;
//     font-weight: 700;
//     font-size: 15px;
//     line-height: 30px;
//   }
// }

// .step {
//   height: 15px;
//   width: 15px;
//   margin: 0 2px;
//   background-color: #bbbbbb;
//   border: none;
//   border-radius: 50%;
//   display: inline-block;
//   opacity: 0.5;
// }

// .step.active {
//   // opacity: 1;
//   background-color: $primary;
// }
// .step.finish {
//   background-color: #04aa6d;
// }

// #twoo {
//   display: none;
// }
// #threee {
//   display: none;
// }

// //  First step starts here
// .number__holder {
//   text-align: left;
//   display: flex;
//   p:first-child {
//     background: $primary;
//     // padding: 0.3rem 0.69rem;
//     border-radius: 50px;
//     color: $secondary;
//     text-align: center;
//     align-items: baseline;
//     margin-right: 16px;
//     font-family: Gotham;
//     font-style: normal;
//     font-weight: normal;
//     font-size: 16px;
//     width: 1.5rem;
//     height: 1.5rem;
//     padding: 0.5rem;
//     line-height: 11px;
//     // width: 2rem;
//     // height: 1.5rem;
//   }
//   p:nth-child(2) {
//     font-family: Gotham;
//     font-style: normal;
//     font-weight: bold;
//     font-size: 13px;
//     line-height: 22px;
//     color: $black;
//     margin-right: 1rem;
//   }
// }

// .sheltaAccount__details {
//   background: rgba(64, 123, 255, 0.46);
//   margin: 0 -16px 15px -16px;
//   // margin-bottom: 15px;
//   div {
//     display: flex;
//     justify-content: space-between;
//     margin: 0 3.5rem;
//     padding-top: 10px;
//     p:first-child {
//       font-family: Gotham;
//       font-style: normal;
//       font-weight: bold;
//       font-size: 16px;
//       line-height: 30px;
//       color: $black;
//       text-align: left;
//       margin-bottom: 0;
//     }

//     p:nth-child(2) {
//       font-family: Gotham;
//       font-style: normal;
//       font-weight: bold;
//       font-size: 16px;
//       line-height: 22px;
//       color: $black;
//       text-align: right;
//       margin-bottom: 0;
//     }
//   }
// }

// .next-btn {
//   background: $primary;
//   border-radius: 10px;
//   font-family: Gotham;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 18px;
//   line-height: 30px;
//   color: $secondary;
//   margin-bottom: 1rem;
//   margin-top: 2rem;
//   box-shadow: none;
// }

// .desclaimer__text {
//   // padding-top: 4rem;
//   p {
//     font-family: Gotham;
//     font-style: normal;
//     font-weight: 500;
//     font-size: 15px;
//     line-height: 30px;
//     color: $primary;
//     text-align: left;
//     margin-left: 2.5rem;
//   }
// }

// .modal-foot {
//   border-top: none;
// }
// // Firts modal steps ends

// // Secosn modal steo start
// .info__text {
//   font-family: Gotham;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 18px;
//   line-height: 30px;
//   color: $black;
//   text-align: left;
// }

// .dateOf__payment {
//   label {
//     font-style: normal;
//     font-weight: 500;
//     font-size: 16px;
//     line-height: 30px;
//     display: flex;
//     color: $black;
//   }
//   input {
//     width: 100%;
//     border-bottom: 0.5px solid #6c6c6c !important;
//     border: none;
//     border-radius: 0;
//     box-shadow: none;
//   }
// }

// .upload__file {
//   .dropzone {
//     border: 0.5px solid #c4c4c4;
//     box-sizing: border-box;
//     border-radius: 10px;
//     margin: 20px 0;
//     padding: 50px 5px;
//     min-height: 200px;
//     cursor: pointer;
//     position: relative;
//     p {
//       margin-bottom: 0;
//       padding-top: 10px;
//       font-family: Gotham;
//       font-style: normal;
//       font-weight: normal;
//       font-size: 16px;
//       line-height: 30px;
//       color: $black;
//     }
//     .browse__span {
//       color: $primary;
//     }
//   }
// }
// .dropzone:hover {
//   background-color: lightgrey;
// }

// .input-file {
//   opacity: 0;
//   width: 100%;
//   left: 0%;
//   top: 0%;
//   height: 215px;
//   position: absolute;
//   cursor: pointer;
//   z-index: 1000;
// }
// .actual_image {
//   top: 0;
//   width: 100%;
//   height: 100%;
//   object-fit: contain;
//   z-index: 0;
//   position: absolute;
//   left: 0%;
//   cursor: pointer;
// }

// .third-step {
//   p {
//     font-family: Gotham;
//     font-style: normal;
//     font-weight: normal;
//     font-size: 15px;
//     line-height: 30px;
//     // display: flex;
//     // align-items: flex-end;
//     text-align: center;
//     color: $black;
//     padding-top: 3rem;
//     padding-bottom: 1rem;
//   }
// }

// .call-btn {
//   background: #f2f5fe;
//   border: 0.5px solid #6c6c6c;
//   box-sizing: border-box;
//   border-radius: 10px;
//   margin-bottom: 10px;
//   // width: 154px;
//   font-family: Gotham;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 20px;
//   line-height: 30px;
//   box-shadow: none;
//   // display: flex;
//   // align-items: flex-end;
//   text-align: center;
//   color: $black;
//   padding: 6px 26px;
//   a {
//     text-decoration: none !important;
//     font-family: Gotham;
//     font-style: normal;
//     font-weight: normal;
//     img {
//       width: 27.01px;
//       margin-right: 1rem;
//     }
//   }
// }

// .back__arrow {
//   padding-top: 0.7rem;
//   cursor: pointer;
//   margin-right: 10px;
// }

// // Second modal step ends

// // Bank transfer modal upload ends

@media screen and (max-width: 599px) {
  .card_holder {
    padding-top: 20px;
  }
  .otp__btn {
    p {
      font-size: 12px;
    }
  }
  .agreement_text_header {
    h5 {
      font-size: 16px;
      line-height: 25px;
      padding: 28px 18px 0;
    }
    .blue_line {
      margin-left: 18px;
      width: 115px;
      height: 3px;
    }
  }
  .tenancy_header {
    h6 {
      margin: 20px 20px 2px;
      font-size: 15px;
    }
  }
  .agreement_body {
    h6 {
      padding: 25px 20px;
    }
  }
  .form_check {
    margin: 19px 20px 19px 20px;
  }
  .sign_agg_btn {
    padding: 19px 20px 42px;
  }

  // Fund me part begins
  .info__text {
    text-align: justify;
  }
  .funds_wrap {
    margin: 15px 0;
    padding: 40px 0;
  }
  .funds_options {
    margin: 10px 0px;
  }
  .document_text {
    font-size: 22px;
  }
  .check_img {
    img {
      width: 54px;
    }
  }
  // Fund me part ends

  // Bank transfer
  // .otp-holder {
  //   input {
  //     width: 40px;
  //   }
  // }
  // .number__holder {
  //   p:nth-child(2) {
  //     line-height: 23px;
  //     text-align: left;
  //     font-size: 13px;
  //   }
  // }
  // .sheltaAccount__details {
  //   div {
  //     margin: 5px 15px;
  //     p:first-child {
  //       font-size: 13px;
  //     }
  //     p:nth-child(2) {
  //       font-size: 13px;
  //     }
  //   }
  // }
  // .second__number {
  //   padding-top: 2rem;
  // }
  // .desclaimer__text {
  //   // padding-top: 1rem;
  //   p {
  //     font-size: 13px;
  //     line-height: 25px;
  //     text-align: left;
  //   }
  // }
  // .payment-header {
  //   font-size: 15px;
  // }
  // .third-step {
  //   p {
  //     font-size: 13px;
  //     line-height: 24px;
  //     text-align: left;
  //     padding-top: 2rem;
  //   }
  // }
  // .call-btn {
  //   font-size: 13px;
  //   padding: 6px 34px;
  //   a {
  //     img {
  //       margin-right: 2rem;
  //       width: 20.01px;
  //     }
  //   }
  // }
  // .third-step {
  //   img {
  //     width: 7rem;
  //   }
  // }
  // // Bank transfer
}
</style>
